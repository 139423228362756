<template>
  <div id="container" v-bind:style="cssThemeColor">
    <!--header-->
    <header id="header">
      <div class="container-fluid">
        <div class="d-flex">
          <div class="header-content-wrapper">
            <ul class="d-flex list-unstyled">
              <li>
                <div class="logo">
                  <template v-if="isBrandSelected">
                    <img
                      v-bind:src="selectedBrand.logo"
                      v-bind:alt="selectedBrand.name"
                      style="height: 30px"
                    />
                  </template>
                  <template v-else>
                    <img src="./../../assets/images/bsh.png" alt="BSH" />
                  </template>
                </div>
              </li>
              <li>
                <div class="greeting text-primary">
                  <span
                    >Welcome
                    {{
                      currentUser != null ? currentUser.first_name : ""
                    }}</span
                  >
                </div>
              </li>
            </ul>
          </div>
          <div class="header-content-wrapper">
            <ul class="d-flex list-unstyled">
              <li>
                <span class="time"
                  >{{ new Date() | moment("dddd Do MMMM YYYY") }}
                </span>
              </li>
              <li>
                <div class="notification">
                  <router-link to="/engineer-performance" id="engineer-performance-button">
                     <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" height="20" viewBox="0 0 75.5 49.44">
  <path d="M86.75,72.7h-5V27.25a2,2,0,0,0-2-2h-8.7a2,2,0,0,0-2,2V72.7H64.52V47a2,2,0,0,0-2-2h-8.7a2,2,0,0,0-2,2V72.7H47.29V39.57a2,2,0,0,0-2-2h-8.7a2,2,0,0,0-2,2V72.7H30V56.19a2,2,0,0,0-2-2H19.32a2,2,0,0,0-2,2V72.7H13.25a1,1,0,0,0,0,2h73.5a1,1,0,0,0,0-2ZM71.06,27.25h8.7V72.49h-8.7ZM53.82,47h8.7v25.5h-8.7ZM36.59,39.57h8.7V72.49h-8.7ZM19.32,56.19H28v16.3H19.32Z" transform="translate(-12.25 -25.25)" fill="#095792"/>
</svg>

                     </router-link>
                </div>
              </li>
              <li>
                <button v-on:click="logout" class="logout" id="logout-button">Logout</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
    <!--header-->
    <div class="d-lg-flex">
      <aside
        id="sidebar"
        v-bind:style="[
          isBrandSelected ? { background: selectedBrand.accent_color } : {},
        ]"
      >
        <button @click="menuOpen()" class="open-menu-btn d-lg-none">
          <span class="fa fa-bars"></span>
        </button>
        <nav class="navigation">
          <div class="scrollable-content">
            <button @click="menuClose()" class="close-menu-btn d-lg-none">
              <span class="fa fa-times"></span>
            </button>
            <ul class="list-unstyled">
              <li>
                <router-link to="/" id="home-button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35.758"
                    height="32.68"
                    viewBox="0 0 35.758 32.68"
                  >
                    <path
                      id="Path_1745"
                      data-name="Path 1745"
                      d="M490.969,520.765v15.019a2,2,0,0,1-2,2h-9.42V528.69H473.4v9.094h-9.42a2,2,0,0,1-2-2V520h1.534v15.786a.468.468,0,0,0,.465.465h7.887v-7.857a1.238,1.238,0,0,1,1.236-1.236h6.747a1.238,1.238,0,0,1,1.236,1.236v7.857h7.887a.468.468,0,0,0,.465-.465V520h1.534v.767Zm-15.21-15.372h0l-.006.007-16.963,13.41a.769.769,0,0,0-.126,1.077h0a.769.769,0,0,0,1.077.126l16.637-13.153,16.637,13.153a.769.769,0,0,0,1.077-.126h0a.769.769,0,0,0-.126-1.077L477,505.4l-.006-.007h0a.808.808,0,0,0-1.24,0Z"
                      transform="translate(-458.5 -505.103)"
                      fill="#fff"
                      fill-rule="evenodd"
                    />
                  </svg>
                  Home
                </router-link>
              </li>
              <li>
                <router-link to="/compare-products" id="compare-button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="37.383"
                    height="36.314"
                    viewBox="0 0 37.383 36.314"
                  >
                    <path
                      id="Path_1741"
                      data-name="Path 1741"
                      d="M218.479,139.6H204.413l5.05-5.051-2.2-2.2-8.81,8.811,8.81,8.811,2.2-2.2-4.95-5.048v0h13.967a7.8,7.8,0,0,1,7.788,7.788,7.7,7.7,0,0,1-.522,2.8l2.9,1.129a10.745,10.745,0,0,0,.735-3.925A10.917,10.917,0,0,0,218.479,139.6Zm-6.23,13.64,4.95,5.051H202.9a7.8,7.8,0,0,1-7.788-7.788,7.665,7.665,0,0,1,.377-2.388l-2.963-.964A10.77,10.77,0,0,0,192,150.5a10.917,10.917,0,0,0,10.9,10.9h14.4l-5.05,5.051,2.2,2.2,8.81-8.811-8.81-8.811Z"
                      transform="translate(-192 -132.343)"
                      fill="#fff"
                    />
                  </svg>
                  <span class="badge" v-if="selectedProducts.length > 0">{{
                    selectedProducts.length
                  }}</span>
                  Compare
                </router-link>
              </li>
              <li>
                <a href="#" data-toggle="modal" data-target="#search-modal" id="search-button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="33.37"
                    height="33.601"
                    viewBox="0 0 33.37 33.601"
                  >
                    <g
                      id="_11"
                      data-name="11"
                      transform="translate(-2257.893 -2256.437)"
                    >
                      <g id="Group_5866" data-name="Group 5866">
                        <path
                          id="Path_1739"
                          data-name="Path 1739"
                          d="M2530.608,2535.7a2.29,2.29,0,0,1-1.63-.675l-6.974-6.974a.54.54,0,0,1,.763-.763l6.974,6.974a1.226,1.226,0,0,0,1.733,0l.753-.753a1.225,1.225,0,0,0,0-1.733l-6.933-6.934a.54.54,0,0,1,.763-.763l6.933,6.933a2.305,2.305,0,0,1,0,3.26l-.752.753A2.29,2.29,0,0,1,2530.608,2535.7Z"
                          transform="translate(-243.027 -246.282)"
                          fill="#fff"
                          stroke="#fff"
                          stroke-width="1.25"
                        />
                        <path
                          id="Path_1740"
                          data-name="Path 1740"
                          d="M2271.863,2283.752a13.345,13.345,0,1,1,9.437-3.909A13.257,13.257,0,0,1,2271.863,2283.752Zm0-25.611a12.266,12.266,0,1,0,8.673,3.593A12.184,12.184,0,0,0,2271.863,2258.141Z"
                          fill="#fff"
                          stroke="#fff"
                          stroke-width="1.25"
                        />
                      </g>
                    </g>
                  </svg>
                  Quick Search
                </a>
              </li>
              <li>
                <router-link to="/profile" id="profile-button"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36.97"
                    height="36.97"
                    viewBox="0 0 36.97 36.97"
                  >
                    <g
                      id="_3"
                      data-name="3"
                      transform="translate(-2227.716 -360.226)"
                    >
                      <g
                        id="Group_5750"
                        data-name="Group 5750"
                        transform="translate(2227.841 360.351)"
                      >
                        <path
                          id="Path_1732"
                          data-name="Path 1732"
                          d="M2246.2,397.071a18.36,18.36,0,1,1,12.982-5.378A18.24,18.24,0,0,1,2246.2,397.071Zm0-35.645a17.285,17.285,0,1,0,17.284,17.285A17.3,17.3,0,0,0,2246.2,361.426Z"
                          transform="translate(-2227.841 -360.351)"
                          fill="#fff"
                          stroke="#fff"
                          stroke-width="0.25"
                        />
                      </g>
                      <g
                        id="Group_5751"
                        data-name="Group 5751"
                        transform="translate(2239.497 370.785)"
                      >
                        <path
                          id="Path_1733"
                          data-name="Path 1733"
                          d="M2386.271,509.584a6.7,6.7,0,1,1,6.7-6.7A6.713,6.713,0,0,1,2386.271,509.584Zm0-12.333a5.629,5.629,0,1,0,5.628,5.629A5.635,5.635,0,0,0,2386.271,497.251Z"
                          transform="translate(-2379.566 -496.175)"
                          fill="#fff"
                          stroke="#fff"
                          stroke-width="0.25"
                        />
                      </g>
                      <g
                        id="Group_5752"
                        data-name="Group 5752"
                        transform="translate(2235.54 383.092)"
                      >
                        <path
                          id="Path_1734"
                          data-name="Path 1734"
                          d="M2348.843,666.832a.538.538,0,0,1-.538-.538c0-4.877-4.3-8.844-9.586-8.844s-9.585,3.968-9.585,8.844a.538.538,0,0,1-1.076,0c0-5.47,4.782-9.92,10.661-9.92s10.662,4.45,10.662,9.92A.538.538,0,0,1,2348.843,666.832Z"
                          transform="translate(-2328.058 -656.374)"
                          fill="#fff"
                          stroke="#fff"
                          stroke-width="0.25"
                        />
                      </g>
                    </g>
                  </svg>
                  My Profile
                </router-link>
              </li>
            </ul>
          </div>
        </nav>
      </aside>
      <slot v-bind:currentUser="currentUser"></slot>
      <div class="modal search-modal" id="search-modal">
        <div class="wrapper">
          <button class="close-modal" data-dismiss="modal" aria-label="Close">
            ×
          </button>
          <h2>What are you looking for?</h2>
          <form class="form" @submit.prevent="submitQuickSearchForm" novalidate>
            <div class="input-group mb-4">
              <input
                type="text"
                class="form-control"
                placeholder="Search product name or code"
                v-model="query"
                @input="query = query.replace(/[\/\\]/g, '')"
              />
              <div class="input-group-prepend">
                <button type="submit" class="btn btn-alt btn-primary" id="input-search-button">
                  Search
                </button>
              </div>
            </div>
            <p class="text-white">Note: Enter the model number not the ENR number.</p>
            <p class="font-weight-bold text-white">Example KUR15A50GB/02 will not display any results, KUR15A50GB will show the replacement options.</p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* global $ */
import AuthService from "@/services/auth.service";
import ProductService from "@/services/product.service";

export default {
  name: "BaseLayout",
  components: {},
  props: {},
  data: function () {
    return {
      currentUser: null,
      query: "",
    };
  },
  mounted() {
    this.getCurrentUser();
  },
  computed: {
    selectedProducts() {
      return this.$store.state.product.selectedProducts;
    },
    selectedBrand() {
      return this.$store.state.brand.selectedBrand;
    },
    isBrandSelected() {
      return this.$store.state.brand.selectedBrand != null;
    },
    cssThemeColor() {
      return {
        "--themeColor": this.isBrandSelected
          ? this.selectedBrand.accent_color
          : "#005691",
        "--themeColorLight": this.isBrandSelected
          ? String(this.selectedBrand.accent_color).replace(/FF/g, "0d")
          : "#e4f1f7",
        "--themeColorRGB": this.isBrandSelected
          ? this.hextoRGB(
              String(this.selectedBrand.accent_color).replace(/#/g, "")
            )
          : "0, 86, 145",
      };
    },
  },
  methods: {
    getCurrentUser: function () {
      AuthService.currentUser().then((user) => {
        this.currentUser = user;
      });
    },

    menuOpen: function () {
      document.body.classList.add("menu-open");
    },

    menuClose: function () {
      document.body.classList.remove("menu-open");
    },

    hextoRGB: function (color) {
      if (color.length != 8) {
        throw "Only six-digit hex colors are allowed.";
      }

      var hex = color.match(/.{1,2}/g);
      var rgb = [
        parseInt(hex[0], 16),
        parseInt(hex[1], 16),
        parseInt(hex[2], 16),
      ];
      return rgb;
    },

    submitQuickSearchForm: function () {
      ProductService.searchEvent(this.query).then(() => {
      $("#search-modal").modal("hide");
      this.$router.push({ path: "/products", query: { q: this.query } });
      this.query = "";
      });
    },

    logout: function () {
      AuthService.logout().then(() => {
        this.$store
          .dispatch("logout")
          .then(() => this.$router.push("/login"))
          .catch((err) => console.log(err));
      });
    },
  },
};
</script>
<style>
.theme-bg {
  background-color: var(--themeColor) !important;
}

.theme-text {
  color: var(--themeColor) !important;
}
</style>
